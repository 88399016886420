/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.content{
  width:600px;
  margin:0 auto;
  :global(.tea-form__label){
    label,legend{
      font-size:14px;
      color:black;
    }
  }
  :global(.tea-input), :global(.tea-textarea), :global(.tea-textarea-group), :global(.tea-text-label), :global(.tea-dropdown__value){
    font-size: 14px;
  }
  :global(.tea-text-label){
    display: inline-block;
    width:400px;
  }
  :global(.tea-dropdown__header){
    height: 40px;
    >:global(.tea-icon){
      top:10px;
    }
  }
  :global(.tea-dropdown-btn) :global(.tea-dropdown__value){
    line-height: 40px;
  }
  :global(.tea-input), :global(.tea-text-label){
    height:40px;
    width:400px;
  }
  .rule{
    width:580px;
    margin: 20px auto 0;
    .rule_i{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    .rule_i_d{
      text-indent: 20px;
    }
  }
}
.node_item{
  margin:20px 0px;
  border-top:1px solid #ccc;
  position: relative;
  padding:30px 0 10px;
}
.node_item_close{
  position:absolute;
  top:5px;
  right: 0px;
  width: 20px;
  height:20px;
  border:1px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add_node{
  display: flex;
  justify-content: center;
  align-items: center;
}